<template>
  <div>
    <div :class="containerClasses">
      <!-- Top Info -->
      <div v-if="!preview" class="player-title">
        <div class="d-flex align-items-center play-btn-container">
          <div class="d-flex align-items-center play-btn-container">
            <div v-if="loading"></div>
            <button v-if="!loading && playing" class="btn btn-flat" @click="pause">
              <font-awesome-icon icon="pause"></font-awesome-icon>
            </button>
            <button v-if="!loading && !playing" class="btn btn-flat" @click="play">
              <font-awesome-icon icon="play"></font-awesome-icon>
            </button>
          </div>

          <div class="timer">{{ timer }}</div>

          <span class="track" style="flex-grow: 1; margin: 0 .5rem">
            {{ name }}
          </span>

          <a class="btn btn-flat" :href="downloadFile || filepath" target="_blank">
            <span v-if="downloadName" class="mr-1">{{ downloadName }}</span>
            <font-awesome-icon icon="download"></font-awesome-icon>
          </a>
          <div class="duration">{{ duration }}</div>
        </div>
      </div>

      <!-- Progress -->
      <div v-if="!preview">
        <div class="bar"></div>
        <div class="music-progress" :style="{ width: loadingProgress < 100 ? (loadingProgress + '%') : progressWidth }"></div>
      </div>
      <!--<div v-if="!waveformImage" class="waveform" ref="waveform" @click="waveformClick"></div>-->
      <img
        v-if="waveformImage"
        class="waveform"
        :src="waveformImage"
      >

      <div
        class="component-container"
        ref="componentContainer"
      ></div>

      <!--<div class="waveAnalyzer" ref="waveAnalyzer"></div>-->
    </div>
  </div>
</template>

<script>

import { debounce } from 'lodash'

import ComponentContainer from '@classes/component-container'

import WaveAnalyzerNode from '@classes/wave-analyzer-node'
import FreqAnalyzerNode from '@classes/freq-analyzer-node'

import WavePreviewNode from '@classes/wave-preview-node'

import FreqScaleNode from '@classes/freq-scale-node'
import DbScaleNode from '@classes/db-scale-node'

import FpsNode from '@classes/fps-node'

import player from '@classes/player'

import app from '../main'
import store from '../store'


export default {
  name: 'MusicPlayer',
  props: {
    filename: { type: String, required: true },
    name: { type: String, required: true },
    extension: { type: String, required: true },
    bgIndex: { type: Number, required: false, default: undefined },
    downloadFile: { type: String, required: false, default: null },
    downloadName: { type: String, required: false, default: null },

    analyzerSmoothingTimeConstant: { type: Number, required: false, default: 0.5 },
    analyzerMinDecibels: { type: Number, required: false, default: -90 }, // -100 dB
    analyzerMaxDecibels: { type: Number, required: false, default: -10 }, // -30 dB
    fftSize: { type: Number, required: false, default: 8192 },
    analyzerMode: { type: Number, required: false, default: 10 },

    freqScale: { type: String, required: false, default: 'log' }, // or none
    ampScale: { type: String, required: false, default: 'log' }, // or none

    preview: { type: Boolean, required: false, default: false },
    showFps: { type: Boolean, required: false, default: false },
    fpsLimit: { type: Number, required: false, default: 0 },

    showPeaks: { type: Boolean, required: false, default: false },
    windowSize: { type: Number, required: false, default: 1 },
  },
  data() {
    return {
      loading: true,

      initProgress: 0,

      sound: null,
      timer: '',
      duration: '',
      playing: false,
      showVolume: false,

      componentContainer: null,

      waveformData: [],

      canvasCtx: null,
      canvasEl: null,

      canvasWaveCtx: null,
      canvasWaveEl: null,

      progressWidth: 0,

      canvasPadding: 20,
      bgClass: '',
      randomColor: null,
      filepath: '',

      waveformImage: null,

      scaleX: null,
      scaleY: null,
      scaleXHeight: 20,

      wavePreviewHeight: 200,
      wavePreviewPadding: 20,

      freqAnalyzers: [],
      waveAnalyzers: [],

      wavePreviewNode: null,

      runId: null,

    }
  },
  computed: {
    song() {
      return store.getters['song'](this.filename)
    },
    loadingProgress() {
      return this.initProgress || this.song.progress
    },
    containerClasses() {
      const classes = [this.bgClass]

      if (this.preview) {
        classes.push('preview-container')
      } else {
        classes.push('player-container')
      }

      return classes
    },
    theme() {
      return this.$store.getters.theme
    },
    scaleXOptions() {
      return {
        height: 24,
        minFreq: 2e1,
        maxFreq: 2e4,
        theme: this.theme,
        freqScale: this.freqScale,
      }
    },
    scaleYOptions() {
      return {
        minDb: this.analyzerMinDecibels,
        maxDb: this.analyzerMaxDecibels,
        theme: this.theme,
      }
    },
    freqAnalyzerOptions() {
      return {
        minDb: this.analyzerMinDecibels,
        maxDb: this.analyzerMaxDecibels,
        audioInputNode: this.sound.gainNode,
        audioCtx: player.ctx,
        fftSize: this.fftSize,
        theme: this.theme,
        freqScale: this.freqScale,
        minFreq: 2e1,
        maxFreq: 2e4,
        mode: this.analyzerMode,
        smoothingTimeConstant: this.analyzerSmoothingTimeConstant,
        showPeaks: this.showPeaks,
        color: this.randomColor,
      }
    },
    wavePreviewOptions() {
      return {
        theme: this.theme,
        color: this.randomColor,
        ampScale: this.ampScale,
        buffer: this.sound.buffer,
        padding: this.wavePreviewPadding,
        height: 150,
      }
    },
    waveAnalyzerOptions() {
      return {
        theme: this.theme,
        color: this.randomColor,
        ampScale: this.ampScale,
        audioInputNode: this.sound.gainNode,
        audioCtx: player.ctx,
        fftSize: this.fftSize,
        smoothingTimeConstant: this.analyzerSmoothingTimeConstant,
        padding: 20,
        windowSize: this.windowSize,
      }
    },
    componentContainerOptions() {
      const options = {
        width: '100%',
        height: this.$refs.componentContainer.clientHeight,
        theme: this.theme,
        containerEl: this.$refs.componentContainer,
      }

      if (this.preview) {
        options.rows = 1
        options.cols = 1
      }

      return options

    },
    allNodes() {
      return [
        this.scaleX,
        this.scaleY,
        this.fpsNode,
        this.wavePreviewNode,
        ...this.freqAnalyzers,
        ...this.waveAnalyzers,
      ]
    },
  },
  async created() {
    // const randomColors = ['#8ea87d', '#5f9ad6', '#ec7a00', '#2fad8a']
    const randomColors = ['#e996a9', '#5099EC', '#97e996', '#e8e996', '#e9b296']

    if (this.bgIndex !== undefined) {
      this.randomColor = randomColors[this.bgIndex % randomColors.length]

    } else {
      const randomIndex = Math.floor(Math.random() * randomColors.length)
      this.randomColor = randomColors[randomIndex]

    }

    const debouncedResizeComponentContainer = debounce(
      () => {
        // has 100% and ref height
        console.log('resize', this.componentContainerOptions)
        this.componentContainer.updateOptions(this.componentContainerOptions)
        this.componentContainer.hideResize()
      },
      500,
    )

    window.addEventListener('resize', () => {
      let startDrawing = false
      if (!this.sound.playing && !this.componentContainer._resizeMode) {
        startDrawing = true
      }

      this.componentContainer.showResize()

      if (startDrawing) {
        requestAnimationFrame(this.draw.bind(this))
      }

      debouncedResizeComponentContainer()

    })

    if (this.preview) {
      this.initPreview()

    } else {
      this.init()

    }

  },
  watch: {
    async extension() {
      const playing = this.playing
      const previousSeek = this.sound && this.sound.seek()
      console.log('watch extension previousSeek', previousSeek)
      this.init({
        restartPlayback: playing,
        previousSeek,
      })
    },
    analyzerSmoothingTimeConstant(value) {
      this.freqAnalyzers.forEach(analyzer => analyzer.updateOptions({ smoothingTimeConstant: value }))
      this.waveAnalyzers.forEach(analyzer => analyzer.updateOptions({ smoothingTimeConstant: value }))
    },
    analyzerMinDecibels(value) {
      this.freqAnalyzers.forEach(analyzer => analyzer.updateOptions({ minDb: value }))
      this.scaleY.updateOptions({ minDb: value })
      this.scaleY.draw()
    },
    analyzerMaxDecibels(value) {
      this.freqAnalyzers.forEach(analyzer => analyzer.updateOptions({ maxDb: value }))
      this.scaleY.updateOptions({ maxDb: value })
      this.scaleY.draw()
    },
    fftSize(value) {
      this.freqAnalyzers.forEach(analyzer => analyzer.updateOptions({ fftSize: value }))
      this.waveAnalyzers.forEach(analyzer => analyzer.updateOptions({ fftSize: value }))
      /* this.analyzer.fftSize = value
      const bufferLength = this.analyzer.frequencyBinCount
      this.analyzerDataArray = new Uint8Array(bufferLength) */
    },
    freqScale(value) {
      this.scaleX.updateOptions({ freqScale: value })
      this.freqAnalyzers.forEach(analyzer => analyzer.updateOptions({ freqScale: value }))
    },
    ampScale(value) {
      this.waveAnalyzers.forEach(analyzer => analyzer.updateOptions({ ampScale: value }))
      this.wavePreviewNode.updateOptions({ ampScale: value })

      if (!this.playing) {
        this.wavePreviewNode.draw()
        this.draw()
      }
    },
    theme(value) {
      if (this.preview) {
        this.initPreview()

      } else {
        this.componentContainer.updateNodeOptions({ theme: value })
        this.draw()

      }
    },
    fpsLimit(value) {
      this.fpsNode.updateOptions({ fpsLimit: value })
    },
    analyzerMode(value) {
      this.freqAnalyzers.forEach(analyzer => analyzer.updateOptions({ mode: value }))
    },
    showPeaks(value) {
      this.freqAnalyzers.forEach(analyzer => analyzer.updateOptions({ showPeaks: value }))
    },
    windowSize(value) {
      this.waveAnalyzers.forEach(analyzer => analyzer.updateOptions({ windowSize: value }))
      if (!this.playing) {
        this.waveAnalyzers.forEach(analyzer => analyzer.draw())
      }
    },
  },
  methods: {
    async init({ cachePreview, restartPlayback, reDraw, previousSeek } = {}) {
      player.stop()
      player.removeAll()

      await store.dispatch('loadSongData', {
        filename: this.filename,
        extension: this.extension,
      })

      const self = this

      this.sound = await player.addSound({
        arrayBuffer: this.song.data[this.extension].slice(),
        filename: this.filename,
        events: {
          onplay() {
            self.playing = true
            requestAnimationFrame(self.getAnalyzerBuffer.bind(self))
            requestAnimationFrame(self.draw.bind(self))
            requestAnimationFrame(self.step.bind(self))

          },
          onend() {
            self.playing = false

          },
          onpause() {
            self.playing = false

          },
          onstop() {
            self.playing = false

          },
          onseek() {
            requestAnimationFrame(self.getAnalyzerBuffer.bind(self))
            requestAnimationFrame(self.draw.bind(self))
            requestAnimationFrame(self.step.bind(self))
          },
        }
      })

      if (previousSeek) {
        if (restartPlayback) {
          this.play()
        }
        console.log('init previousSeek', previousSeek)
        this.sound.seek(previousSeek)
      }

      this.duration = this.formatTime(this.sound.duration)

      if (this.componentContainer && reDraw) {
        this.componentContainer.reset()

        delete this.componentContainer
        for (let i = 0; i < this.allNodes.length; i++) {
          delete this.allNodes[i]
        }
      }

      if (!this.componentContainer) {
        this.componentContainer = new ComponentContainer(this.componentContainerOptions)
        // this.setupComponentContainer()

        this.initProgress = 20

        this.wavePreviewNode = new WavePreviewNode()
        this.componentContainer.addNode({
          node: this.wavePreviewNode,
          options: this.wavePreviewOptions,
        })
        this.wavePreviewNode.calcWaveformData()
        this.wavePreviewNode.draw()
        this.wavePreviewNode.addClickListener(
          this.waveformClick.bind(this)
        )

        if (cachePreview) {
          this.wavePreviewNode._canvas.toBlob(
            (blob) => {
              const formData = new FormData()
              formData.append('file', blob, this.filename)

              fetch(
                '/cache-preview/' + this.filename + '/' + this.theme,
                {
                  body: formData,
                  method: 'post',
                }
              )
            },
            'image/webp',
            .9
          )
        }

        this.initProgress = 60

        if (!this.preview) {
          const freqAnalyzer = new FreqAnalyzerNode()
          this.freqAnalyzers.push(freqAnalyzer)
          this.componentContainer.addNode({
            node: freqAnalyzer,
            options: this.freqAnalyzerOptions,
          })

          this.scaleX = new FreqScaleNode()
          this.scaleY = new DbScaleNode()
          freqAnalyzer.addScaleX(this.scaleX, this.scaleXOptions)
          freqAnalyzer.addScaleY(this.scaleY, this.scaleYOptions)

          /* const waveAnalyzer = new WaveAnalyzerNode()
          this.waveAnalyzers.push(waveAnalyzer)
          this.componentContainer.addNode({
            node: waveAnalyzer,
            options: this.waveAnalyzerOptions,
          }) */

          this.fpsNode = new FpsNode()
          this.componentContainer.addNode({
            node: this.fpsNode,
            options: {
              position: 'absolute',
              width: 50,
              height: 50,
              theme: this.theme,
              fpsLimit: this.fpsLimit,
            },
            isFpsNode: true,
          })

          this.initProgress = 90

        }
      }

      this.initProgress = null
      this.loading = false
      this.$emit('loaded')

    },
    initPreview() {
      try {
        this.waveformImage = require(
          '../../public/waveforms/' + this.filename + '_' + this.theme + '.webp'
        )

      } catch (e) {
        this.waveformImage = null

      } finally {
        if (!this.waveformImage) {
          this.init({ cachePreview: true, reDraw: true })
        }
      }
    },
    play() {
      this.sound.play()

    },
    pause() {
      this.sound.pause()

    },
    formatTime(secs) {
      const minutes = Math.floor(secs / 60) || 0
      const seconds = Math.floor(secs - minutes * 60) || 0

      return minutes + ':' + (seconds < 10 ? '0' : '') + seconds

    },
    seek(per) {
      this.sound.seek(this.sound.duration * per)

    },
    step() {
      const seek = this.sound.seek()
      this.timer = this.formatTime(Math.round(seek))
      this.progressWidth = (((seek / this.sound.duration) * 100) || 0) + '%'
      // store.dispatch('setProgress', { filename: this.filename, progress: progressWidth })

      if (this.sound.playing) {
        requestAnimationFrame(this.step.bind(this))

      }

    },
    waveformClick(event) {
      console.log('waveformClick(event)', event)
      if (!this.sound) {
        return
      }

      const boundingRect = event.target.getBoundingClientRect()
      const w = boundingRect.width
      const l = boundingRect.left

      this.seek((event.clientX - l) / w)

      if (!this.playing) {
        this.play()
      }

    },
    setupComponentContainer() {
      // TODO: remove

      const containerRect = this.$refs.componentContainer.getBoundingClientRect()

      // draw at double resolution
      // const dpr = window.devicePixelRatio * 4 || 4
      const dpr = 1

      if (!this.preview) {

        // analyzer
        /* const canvas = this.canvasEl = document.createElement('canvas')
        canvas.style.width = '100%'
        canvas.style.height = '100%'
        this.$refs.componentContainer.appendChild(canvas)

        canvas.width = containerRect.width * dpr
        // canvas.height = (containerRect.height + 2 * this.canvasPadding) * dpr / 2
        canvas.height = containerRect.height

        const ctx = this.canvasCtx = canvas.getContext('2d') */

      }

    },
    async getAnalyzerBuffer(timestamp) {
      /* this.waveAnalyzers.forEach((wa) => {
        wa.getBuffer(timestamp)
      })

      if (this.sound.playing) {
        requestAnimationFrame(timestamp => this.getAnalyzerBuffer(timestamp));
      } */
    },
    async draw(timestamp) {
      if (this.sound.playing) {
        this.waveAnalyzers.forEach((wa) => {
          wa.getBuffer(timestamp)
        })
      }

      const waitMs = await this.componentContainer.draw(timestamp)

      if (waitMs) {
        await new Promise(_resolve => setTimeout(_resolve, waitMs))
      }

      if (this.sound.playing || this.componentContainer._resizeMode) {
        // requestAnimationFrame(this.draw)
        this.runId = requestAnimationFrame(timestamp => this.draw(timestamp));
      }
    },
  }
}

</script>

<style lang="scss">

/* .bg-1 {
  background: linear-gradient(135deg, #71a5f3 0%, #3d4d91 100%);
}

.bg-2 {
  background: linear-gradient(135deg, #df71f3 0%, #913d3d 100%);
}

.bg-3 {
  background: linear-gradient(135deg, #71dbf3 0%, #3d914b 100%);
}

.bg-4 {
  background: linear-gradient(135deg, #f3c571 0%, #913d4d 100%);
} */

.component-container {
  width: 100%;
  height: 56rem; // player-container - titlebar
}

.player-container {
  height: 60rem;

}

.preview-container {
  height: 10rem;

  .waveform {
    height: 10rem;
  }

  .component-container {
    height: 10rem;
  }
}

.dark-mode .player-title {
  color: #fff !important;
}

.dark-mode {
  .timer, .duration, .btn-play {
    color: #fff !important;
  }
}

.player-container, .preview-container {
  width: 100%;
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  border-radius: 3px;
  // box-shadow: 2px 2px 2px #000;

  .is-transparent {
    margin: auto;
    width: 2rem;
    height: 2rem;
    // background-color: transparent;
    background-color: rgba(0, 0, 0, 0.1);
    border: none;
  }

  /* Top Info */
  .player-title {
    height: 4rem;
    text-align: center;
    font-size: 1.25rem;
    // opacity: 0.9;
    // font-weight: 300;
    // color: #333;
    // text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.33);
    // background: rgba(255, 255, 255, 0.3);
    // background: rgba(0, 0, 0, 0.3);

    .play-btn-container {
      height: 100%;

      .btn-flat {
        height: 100%;
        width: 4rem;
        font-size: 125%;
      }
    }

    .track {
      font-family: 'Shrikhand';
      font-size: 150%;
      font-weight: 400;
    }
  }

  .btn-play {
    color: #555;
  }

  .flex-align-center {
    align-items: center;
  }

  .timer, .duration {
    text-align: left;
    // font-size: 1rem;
    font-weight: 400;
    height: 100%;
    padding: 0 1rem;
    display: flex;
    align-items: center;
  }

  /* Progress */
  .waveform {
    width: 100%;
    // margin-top: 0.125rem;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
  }
  .bar {
    position: relative;
    top: 0rem;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.33);
    opacity: 0.9;
  }
  .music-progress {
    position: relative;
    top: -2px;
    left: 0;
    width: 0%;
    height: 2px;
    margin: 0;
    background-color: #333;
    // z-index: -1;
  }

  /* Loading */
  .loading {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -35px;
    width: 70px;
    height: 70px;
    background-color: #fff;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
    animation: sk-scaleout 1.0s infinite ease-in-out;
  }
  @-webkit-keyframes sk-scaleout {
    0% { -webkit-transform: scale(0) }
    100% {
      -webkit-transform: scale(1.0);
      opacity: 0;
    }
  }
  @keyframes sk-scaleout {
    0% {
      -webkit-transform: scale(0);
      transform: scale(0);
    } 100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
  }

  /* Fade-In */
  .fadeout {
    webkit-animation: fadeout 0.5s;
    -ms-animation: fadeout 0.5s;
    animation: fadeout 0.5s;
  }
  .fadein {
    webkit-animation: fadein 0.5s;
    -ms-animation: fadein 0.5s;
    animation: fadein 0.5s;
  }
  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  @-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  @-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
  }
  @keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
  }
  @-webkit-keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
  }
  @-ms-keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
  }
}

.dark-mode .music-progress {
  background-color: #e996a9;
}


</style>
