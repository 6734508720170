import Node from '@classes/node'


export default class WavePreviewNode extends Node {
  constructor(options) {
    super(options)

    this._waveformData = null
    this._waveformAltData = null

  }

  updateOptions({
    width,
    height,
    theme,
    color,
    ampScale,
    buffer,
    padding,
  } = {}) {
    super.updateOptions(arguments[0] || {})

    if (buffer) {
      this._waveformData = null
      this._waveformAltData = null
    }

    if (width || height || padding) {
      // reset transform
      this._ctx.setTransform(1, 0, 0, 1, 0, 0)
      this._ctx.translate(0, this._canvas.height / 2) // y = 0 is middle
      console.log('wave-preview: updateOptions', {width, height, padding})
    }

    this._blockSize = 1 // i.e. how many pixels is each line

    if (!!this._buffer) {
      this.calcWaveformData()
      this.draw()
    }

  }

  calcWaveformData() {
    const canvas = this._canvas
    const ctx = this._ctx
    const buffer = this._buffer

    const rawData = buffer.getChannelData(0)
    rawData[0] = 0.0
    rawData[rawData.length - 1] = 0.0
    let rawDataRight = null
    if (buffer.numberOfChannels > 1) {
      rawDataRight = buffer.getChannelData(1)
    } else {
      rawDataRight = rawData
    }
    rawDataRight[0] = 0.0
    rawDataRight[rawDataRight.length - 1] = 0.0

    // samples per pixel
    const spp = rawData.length / (canvas.width * this._blockSize)

    // average blocks for each px
    const avgData = []
    const altData = []

    for (let i = 0; i < canvas.width; i++) {
      // nearest-neighbor interpolation of start and end points
      const actualStart = spp * i
      const blockStart = Math.floor(actualStart)
      let blockEnd = Math.ceil(actualStart + spp)

      if (blockEnd >= rawData.length) {
        blockEnd = rawData.length
      }

      const block = rawData.slice(blockStart, blockEnd)
        // .map(v => Math.abs(v))

      let sum = 0
      /* block.forEach(v => sum += Math.abs(v))
      sum /= (blockEnd - blockStart)
      avgData.push(sum) */

      const blockRight = rawDataRight.slice(blockStart, blockEnd)
        // .map(v => Math.abs(v))
      let alt = 0
      for (let i_r = 0; i_r < blockRight.length; i_r++) {
        // mono
        sum += Math.abs((block[i_r] + blockRight[i_r]) / 2)

        // side
        alt += Math.abs((block[i_r] - blockRight[i_r]) / 2)
      }
      sum /= (blockEnd - blockStart)
      alt /= (blockEnd - blockStart)
      avgData.push(sum)
      altData.push(alt)

    }

    // normalize
    const dataMax = Math.max(...avgData)
    const multiplier = 1 / dataMax
    this._waveformData = avgData.map(n => n * multiplier)
    this._waveformAltData = altData.map(n => n * multiplier)

    // const dpr = window.devicePixelRatio * 2
    const dpr = 1

    this.setCanvasAttrs()

  }

  getWaveformGradient({ altMode } = {}) {
    altMode = altMode || false

    if (true || !altMode) {
      const grd = this._ctx.createLinearGradient(
        0, -this._canvas.height / 2, 0, this._canvas.height / 2
      )

      if (
        (this._theme === 'dark' && !altMode) ||
        (this._theme === 'light' && altMode)
      ) {
        grd.addColorStop(0, 'rgba(255, 255, 255, 1)')
        grd.addColorStop(1, this._color)

      } else {
        grd.addColorStop(0, '#00000055')
        grd.addColorStop(.8, '#000f')

      }

      return grd

    } else {
      if (this._theme === 'dark') {
        // return '#00000099'
        const grd = this._ctx.createLinearGradient(
          0, -this._canvas.height / 2, 0, this._canvas.height / 2
        )
        grd.addColorStop(0, '#00000000')
        grd.addColorStop(0.5, '#000000aa')
        grd.addColorStop(1, '#00000000')
        return grd

      } else {
        return this._color + 'ff'

      }
    }

  }

  setCanvasAttrs() {
    this._ctx.lineWidth = 1.2
    this._ctx.strokeStyle = this.getWaveformGradient()
    this._ctx.shadowColor = 'rgba(0, 0, 0, 0.2)'
    this._ctx.shadowBlur = 5
    this._ctx.shadowOffsetX = 5
    this._ctx.shadowOffsetY = 5

  }

  getValue(value) {
    const posOrNeg = value > 0 ? 1 : -1

    // log scale
    if (this._ampScale === 'log') {
      return posOrNeg * Math.max(0.01, (Math.log10(Math.abs(value)) + 1))

    } else {
      return posOrNeg * Math.max(0.01, Math.abs(value))

    }
  }

  drawLine(x, height) {
    this._ctx.moveTo(x, -height)
    this._ctx.lineTo(x, height)

  }

  clear() {
    this._ctx.clearRect(
      0, -this._canvas.height, this._canvas.width, this._canvas.height*2
    )
  }

  async _draw(timestamp) {
    this._ctx.strokeStyle = this.getWaveformGradient()

    this._ctx.beginPath()

    for (let i = 0; i < this._waveformData.length; i++) {
      let value = this.getValue(this._waveformData[i])

      this.drawLine(
        this._blockSize * i,
        value * 0.5 * (this._canvas.height - this._padding),
      )

    }

    this._ctx.stroke()

    // alt data (mono/stereo)
    this._ctx.strokeStyle = this.getWaveformGradient({ altMode: true })
    this._ctx.shadowColor = 'transparent'

    this._ctx.beginPath()

    for (let i = 0; i < this._waveformAltData.length; i++) {
      let value = this.getValue(this._waveformAltData[i])

      this.drawLine(
        this._blockSize * i,
        value * 0.5 * (this._canvas.height - this._padding),
      )

    }

    this._ctx.stroke()

  }

  async drawResizeContainer() {
    this.clear()
    const canvas = this._canvas
    const ctx = this._ctx
    ctx.lineWidth = 2
    ctx.strokeStyle = 'rgba(0, 0, 0, 20)'
    ctx.strokeRect(
      0,
      -canvas.height,
      canvas.width,
      canvas.height * 2,
    )

  }

  addClickListener(fn) {
    this._canvas.addEventListener('click', fn)
  }

}
