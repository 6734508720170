import Node from '@classes/node'

import { hexToHSL } from '@util/color-conversions'


const ROOT24 = 2 ** (1 / 24) // 24th root of 2
const C0 = 440 * ROOT24 ** -114 // ~16.35 Hz


export default class FreqAnalyzerNode extends Node {
  constructor(options) {
    super(options)
  }

  updateOptions({
    width,
    height,
    minDb,
    maxDb,
    audioInputNode,
    audioCtx,
    fftSize,
    theme,
    freqScale,
    minFreq,
    maxFreq,
    mode,
    smoothingTimeConstant,
    showPeaks,
    color,
  } = {}) {
    super.updateOptions(arguments[0] || {})

    if (audioCtx && audioInputNode) {
      this._audioCtx = audioCtx
      this._analyzer = audioCtx.createAnalyser()
      audioInputNode.connect(this._analyzer)
    }

    if (fftSize) {
      this._analyzer.fftSize = fftSize
      this._data = new Uint8Array(this._analyzer.frequencyBinCount)
    }

    if (minDb !== undefined) {
      this._analyzer.minDecibels = minDb
    }

    if (maxDb !== undefined) {
      this._analyzer.maxDecibels = maxDb
    }

    if (smoothingTimeConstant !== undefined) {
      this._analyzer.smoothingTimeConstant = smoothingTimeConstant
    }

    this.lineWidth = 1
    this.globalAlpha = 1

    if (!!this._analyzer) {
      console.log('!!this._analyzer')
      this._calcBars()
    }

  }

  setTheme(theme) {
    super.setTheme(theme)

    this._canvasGradient = this._ctx.createLinearGradient(0, this._canvas.height, 0, 0)
    this._peakGradient = this._ctx.createLinearGradient(0, this._canvas.height, 0, 0)

    if (this._color && theme !== 'dark') {
      const hsl = hexToHSL(this._color)
      const peakColor = 'hsla(' +
        (hsl.h + 180) % 360 + 'deg,' +
        hsl.s + '%,' +
        hsl.l + '%,' +
        '1)'

      this._canvasGradient.addColorStop(0, peakColor)
      this._peakGradient.addColorStop(0, this._color + '77')

    } else {
      if (theme === 'dark') {
        this._canvasGradient.addColorStop(0, '#3d914b')
        this._canvasGradient.addColorStop(1, '#71dbf3')

        this._peakGradient.addColorStop(0, '#913d3d55')
        this._peakGradient.addColorStop(1, '#f3c57155')

      } else {
        this._canvasGradient.addColorStop(0, '#000')
        this._canvasGradient.addColorStop(1, '#913d3d')

        this._peakGradient.addColorStop(0, '#0006')
        this._peakGradient.addColorStop(1, '#913d3d55')
      }
    }

  }

  _freqToBin(freq, rounding='round') {
    const max = this._analyzer.frequencyBinCount - 1
    const bin = Math[rounding](freq * this._analyzer.fftSize / this._audioCtx.sampleRate)

    return bin < max ? bin : max
  }


  _calcBars() {
    const bars = this._bars = [] // initialize object property

    // helper function
    const binToFreq = bin => bin * this._audioCtx.sampleRate / this._analyzer.fftSize

    const canvas  = this._canvas
    const maxFreq = this._maxFreq
    const minFreq = this._minFreq

    let minLog
    let logWidth

    if (this._freqScale === 'linear') {
      // Discrete frequencies or area fill modes
      this._barWidth = 1

      minLog = Math.log10(minFreq)
      logWidth = canvas.width / (Math.log10(maxFreq) - minLog)

      const minIndex = this._freqToBin(minFreq, 'floor')
      const maxIndex = this._freqToBin(maxFreq)

      let lastPos = -999

      for (let i = minIndex; i <= maxIndex; i++) {
        const freq = binToFreq(i) // frequency represented by this index
        const pos  = Math.round(logWidth * (Math.log10(freq) - minLog)) // avoid fractionary pixel values

        // if it's on a different X-coordinate, create a new bar for this frequency
        if (pos > lastPos) {
          bars.push({
            posX: pos,
            dataIdx: i,
            endIdx: 0,
            factor: 0,
            peak: 0,
            hold: null,
            accel: null,
          })

          lastPos = pos

        } else if ( bars.length ) { // otherwise, add this frequency to the last bar's range
          bars[bars.length - 1].endIdx = i

        }

      }
    } else if (
      this._freqScale === 'log' ||
      this._freqScale === 'mel'
    ) { // Octave bands modes aka log mode

      // generate a table of frequencies based on the equal tempered scale

      let notesPerBand

      if (this._mode <= 8) {
        notesPerBand = [0,1,2,3,4,6,8,12,24][this._mode]

      } else {
        notesPerBand = 1

      }

      let i = 0
      let temperedFreq
      let melFreq
      let temperedScale = []

      while ((temperedFreq = C0 * ROOT24 ** i) <= maxFreq) {
        if (temperedFreq >= minFreq && i % notesPerBand == 0) {
          if (this._freqScale === 'mel') {
            melFreq = 2595 * Math.log10(1 + (temperedFreq / 700))
            temperedScale.push(melFreq)

          } else {
            temperedScale.push(temperedFreq)

          }
        }
        i++
      }

      minLog = Math.log10(temperedScale[0])
      logWidth = canvas.width / (Math.log10(temperedScale[temperedScale.length - 1]) - minLog)

      // divide canvas space by the number of frequencies (bars) to display
      this._barWidth = canvas.width / temperedScale.length

      let prevBin = 0  // last bin included in previous frequency band
      let prevIdx = -1 // previous bar FFT array index
      let nBars = 0;  // count of bars with the same index

      temperedScale.forEach((freq, index) => {
        // which FFT bin best represents this frequency?
        const bin = this._freqToBin(freq)

        let idx, nextBin
        // start from the last used FFT bin
        if (prevBin > 0 && prevBin + 1 <= bin) {
          idx = prevBin + 1
        } else {
          idx = bin
        }

        // FFT does not provide many coefficients for low frequencies, so several bars may end up using the same data
        if (idx == prevIdx) {
          nBars++

        } else {
          // update previous bars using the same index with a interpolation factor
          if (nBars > 1) {
            for (let i = 0; i < nBars; i++) {
              bars[bars.length - nBars + i].factor = (i + 1) / nBars
            }
          }
          prevIdx = idx
          nBars = 1

        }

        prevBin = nextBin = bin
        // check if there's another band after this one
        if (index < temperedScale.length - 1) {
          nextBin = this._freqToBin(temperedScale[index + 1])
          // and use half the bins in between for this band
          if (nextBin - bin > 1) {
            prevBin += Math.round((nextBin - bin) / 2)
          }
        }

        const endIdx = prevBin - idx > 0 ? prevBin : 0

        bars.push({
          posX: index * this._barWidth,
          dataIdx: idx,
          endIdx,
          factor: 0,
          peak: 0,
          hold: [],
          accel: []
        })

      })
    }

    this._barSpace = 0

    this._barSpacePx = Math.min(
      this._barWidth - 1,
      (this._barSpace > 0 && this._barSpace < 1) ?
        this._barWidth * this._barSpace : this._barSpace
    )

  }

  async _draw(timestamp) {
    // await super.draw(timestamp)

    const bufferLength = this._analyzer.frequencyBinCount
    const ctx = this._ctx
    const canvas = this._canvas
    const fftSize = this._analyzer.fftSize
    const mode = this._mode

    const analyzerHeight = this._canvas.height
    const channelTop = 0
    const channelBottom = analyzerHeight
    const analyzerBottom = channelTop + analyzerHeight

    ctx.fillStyle = this._canvasGradient
    ctx.strokeStyle = this._canvasGradient

    const fftData = this._data
    this._analyzer.getByteFrequencyData(fftData)

    ctx.beginPath()

    // compute the effective bar width, considering the selected bar spacing
    let width = this._barWidth - (this._freqScale === 'linear' ? 0 : Math.max(0, this._barSpacePx));

    // make sure width is integer for pixel accurate calculation, when no bar spacing is required
    if (this._barSpace == 0) {
      width |= 0;
    }

    let currentEnergy = 0

    for (let i = 0; i < this._bars.length; i++) {
      let bar = this._bars[i]
      let barHeight = 0

      if (bar.endIdx == 0) { // single FFT bin
        barHeight = fftData[bar.dataIdx]
        // perform value interpolation when several bars share the same bin, to generate a smooth curve
        if (bar.factor) {
          const prevBar = bar.dataIdx ? fftData[bar.dataIdx - 1] : barHeight
          barHeight = prevBar + (barHeight - prevBar) * bar.factor
        }

      } else { // range of bins
        // use the highest value in the range
        for (let j = bar.dataIdx; j <= bar.endIdx; j++) {
          barHeight = Math.max(barHeight, fftData[j])
        }

      }

      barHeight /= 255
      currentEnergy += barHeight

      barHeight = barHeight * analyzerHeight | 0

      if (barHeight >= bar.peak) {
        bar.peak = barHeight
        bar.hold = 60; // set peak hold time to 30 frames (0.5s)
        bar.accel = 0
      }

      let adjWidth = width // bar width may need small adjustments for some bars, when barSpace == 0
      let posX = bar.posX

      // Draw current bar or line segment
      if (mode == 10) {
        if ( i == 0 ) {
          // in linear mode, start the line off screen
          ctx.moveTo(-this.lineWidth, analyzerBottom)
          // use value of previous FFT bin
          if (bar.dataIdx) {
            ctx.lineTo(-this.lineWidth, analyzerBottom - fftData[bar.dataIdx - 1] / 255 * analyzerHeight)
          }
        }
        // draw line to current point
        ctx.lineTo(bar.posX, analyzerBottom - barHeight)

      } else {
        if (mode > 0) {
          if (this._barSpace == 0) {
            posX |= 0
            if (i > 0 && posX > this._bars[i - 1].posX + width) {
              posX--
              adjWidth++
            }
          }
          else
            posX += this._barSpacePx / 2
        }

        ctx.fillRect(posX, analyzerBottom, adjWidth, -barHeight)
      }

    }

    ctx.stroke()

    ctx.strokeStyle = this._peakGradient
    ctx.beginPath()

    // ctx.fillStyle = this._peakGradient

    for (let i = 0; i < this._bars.length; i++) {
      let bar = this._bars[i]
      let adjWidth = width
      let posX = bar.posX

      // Draw peak
      if (bar.peak > 1) { // avoid half "negative" peaks on top channel (peak height is 2px)
        if (this._showPeaks) {
          // ctx.fillRect(posX, analyzerBottom - bar.peak, adjWidth, 1)
          if (i > 0) {
            // ctx.moveTo(this._bars[i - 1].posX, analyzerBottom - this._bars[i - 1].peak)
            // ctx.moveTo(posX, analyzerBottom - this._bars[i - 1].peak)
            ctx.lineTo(posX, analyzerBottom - bar.peak)
            // ctx.lineTo(posX + adjWidth, analyzerBottom - bar.peak)
          }
        }

        if (bar.hold) {
          bar.hold--
        } else {
          bar.accel++
          bar.peak -= bar.accel
        }
      }
    }

    // restore global alpha
    ctx.globalAlpha = 1

    // Fill/stroke drawing path for mode 10 and radial
    if (mode == 10) {
      ctx.lineTo(canvas.width + this.lineWidth, analyzerBottom)

      if (this.lineWidth > 0) {
        ctx.lineWidth = this.lineWidth
        ctx.stroke()
      }

      if (this.fillAlpha > 0) {
        ctx.globalAlpha = this.fillAlpha
        ctx.fill()
        ctx.globalAlpha = 1
      }
    }

  }

}
