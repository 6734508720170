export default class ComponentContainer {
  constructor(options) {
    // this._canvas = document.createElement('canvas')
    this._nodes = []
    this._options = {}

    // this._containerEl = document.createElement('div')
    this._containerEl = options.containerEl

    // this._ctx = this._canvas.getContext('2d')

    this._resizeMode = false

    this._rows = options.rows || 2
    this._cols = options.cols || 1

    this._childNodeContainers = []
    for (let i = 0; i < this._rows * this._cols; i++) {
      const childNodeContainer = document.createElement('div')

      childNodeContainer.style['grid-column-start'] = 1 + (i % this._cols)
      childNodeContainer.style['grid-column-end'] = 1 + (i % this._cols)
      childNodeContainer.style['grid-row-start'] = 1 + Math.floor(i / this._cols)
      childNodeContainer.style['grid-row-end'] = 1 + Math.floor(i / this._cols)

      this._containerEl.appendChild(childNodeContainer)
      this._childNodeContainers.push(childNodeContainer)
    }

    this.updateOptions(options)

  }

  updateOptions({ width, height, theme } = {}) {
    if (width) {
      this._options.width = width
    }

    if (height) {
      this._options.height = height
    }

    if (theme) {
      this._theme = theme
    }

    this.setContainerCss()
    if (width || height || theme) {
      this._nodes.forEach((node) => {
        const options = { ...node.options }

        if (width || height) {
          const containerRect = node.nodeContainer.getBoundingClientRect()
          console.log('node containerRect', containerRect)
          options.width = containerRect.width
          options.height = containerRect.height

        }

        node.node.updateOptions(options)

      })
    }

  }

  setContainerCss() {
    let gridTemplateRowsValue = ''
    for (let i = 0; i < this._rows; i++) {
      // find max height of nodes in this row
      let maxHeight = 0
      for (let n = i * this._cols; n < this._cols; n += 1) {
        if (
          this._nodes[n] &&
          this._nodes[n].options.height &&
          this._nodes[n].options.height > maxHeight
        ) {
          maxHeight = this._nodes[n].options.height
        }
      }

      const templateRowValue = maxHeight === 0 ? 'auto' : (maxHeight + 'px')
      gridTemplateRowsValue += templateRowValue + (i === (this._rows - 1) ? '' : ' ')

    }

    // TODO: probably fix this
    let gridTemplateColumnsValue = ''
    for (let i = 0; i < this._cols; i++) {
      // find max width of nodes in this column
      let maxWidth = 0
      for (let n = i * this._rows; n < this._nodes.length; n += this._rows) {
        if (this._nodes[n].options.width && this._nodes[n].options.width > maxWidth) {
          maxWidth = this._nodes[n].options.width
        }
      }
      const templateColumnValue = maxWidth === 0 ? 'auto' : (maxWidth + 'px')
      gridTemplateColumnsValue += templateColumnValue + (i === (this._cols - 1) ? '' : ' ')
    }

    const styles = {
      'display': 'grid',
      'gap': '0px',
      'align-items': 'stretch',
      'justify-items': 'stretch',
      'grid-template-rows': gridTemplateRowsValue,
      'grid-template-columns': gridTemplateColumnsValue,
    }

    const optionalOptions = ['width', 'height']
    optionalOptions.forEach((option) => {
      if (this._options[option]) {
        styles[option] = this._options[option]
      }
    })

    for (const [k, v] of Object.entries(styles)) {
      this._containerEl.style[k] = v
    }

    /* if (this._nodes.length) {
      console.log('re-adding', this._nodes.length, 'nodes')
      const nodesTmp = this._nodes
      this._nodes = []

      this._containerEl.innerHTML = ''
      this._nodes.forEach((node) => {
        this.addNode({
          node: node.node,
          options: node.options,
          isFpsNode: node.isFpsNode,
        })
      })
    } */

  }

  addNode({ node, options, isFpsNode } = {}) {
    options = options || {}
    let nodeContainer
    let position

    if (options.position === 'absolute') {
      position = 'absolute'
      nodeContainer = document.createElement('div')
      nodeContainer.style.position = 'absolute'
      nodeContainer.style.width = options.width
      nodeContainer.style.height = options.height

      this._containerEl.appendChild(nodeContainer)

      node.updateOptions(options)

    } else {
      // const nodeContainer = document.createElement('div')
      // todo filter nodes for not absolute
      position = this._nodes.length
      nodeContainer = this._childNodeContainers[position]

      /* nodeContainer.style['align-self'] = 'stretch'
      nodeContainer.style['justify-self'] = 'stretch' */

      // this._childNodeContainers[position].appendChild(nodeContainer)

      const containerRect = nodeContainer.getBoundingClientRect()
      options.width = options.width || containerRect.width
      options.height = options.height || containerRect.height

      node.updateOptions(options)

    }

    nodeContainer.appendChild(node._canvas)

    this._nodes.push({
      position,
      node,
      nodeContainer,
      isFpsNode,
      options,
    })

    console.log('added node')
    this.setContainerCss()
  }

  updateNodeOptions(options) {
    this._nodes.forEach((node) => {
      node.node.updateOptions(options)
    })
  }

  async draw(timestamp) {
    const promises = []

    this._nodes
    .filter(n => !n.isFpsNode)
    .forEach((node) => {
      if (!this._resizeMode) {
        promises.push(
          node.node.draw(timestamp)
        )

      } else {
        promises.push(
          node.node.drawResizeContainer()
        )

      }
    })

    await Promise.all(promises)

    const fpsNode = this._nodes.find(n => !!n.isFpsNode)
    if (fpsNode) {
      const waitMs = await fpsNode.node.draw(timestamp)
      return waitMs
    }

  }

  showResize() {
    if (!this._resizeMode) {
      console.log('start resize')
      this._resizeMode = true
      // this._containerEl.style.border = '5px solid blue'
    }
  }

  hideResize() {
    this._resizeMode = false
    this._containerEl.style.border = 'none'
  }

  reset() {
    this._nodes.forEach((node) => {
      node.nodeContainer.remove()
    })
    this._nodes = []

    while (this._containerEl.firstChild) {
      this._containerEl.removeChild(this._containerEl.firstChild)
    }
  }

}
