import Node from '@classes/node'


export default class FpsNode extends Node {
  constructor(options = {}) {
    super(options)

    // fps
    this._fps = 0
    this._frame = 0
    this._time = 0

  }

  updateOptions({ width, height, theme, fpsLimit } = {}) {
    super.updateOptions(arguments[0] || {})

    if (fpsLimit !== undefined) {
      this._fpsLimit = fpsLimit
      this._frameTimeLimit = fpsLimit > 0 ? (1000 / fpsLimit) : null
    }

    this._ctx.font = '300 20px \'Josefin Sans\''
    this._ctx.textAlign = 'left'
    if (this._theme === 'dark') {
      this._ctx.fillStyle = '#ec7a00'

    } else {
      this._ctx.fillStyle = '#333333'

    }

  }

  async draw(timestamp) {
    this._frame++
    let elapsedSinceLastDraw = timestamp - this._time
    let elapsedSinceFrameStart = performance.now() - timestamp
    this._time = timestamp

    if ((elapsedSinceLastDraw * this._frame) >= 1000) {
      this._fps = 1000 / elapsedSinceLastDraw
      this._frame = 0

      this._ctx.clearRect(0, 0, this._canvas.width, this._canvas.height)

      this._ctx.fillText(Math.round(this._fps), 10, this._canvas.height/2, this._canvas.width)

    }

    if (this._fpsLimit > 0 && elapsedSinceFrameStart < this._frameTimeLimit) {
      const waitMs = this._frameTimeLimit - elapsedSinceFrameStart
      return waitMs
    }

  }

  drawToCtx(containerCanvasContext) {
    containerCanvasContext.drawImage(
      this._canvas,
      containerCanvasContext.canvas.width - this._canvas.width,
      0,
    )
  }

}
