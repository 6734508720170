import Node from '@classes/node'


export default class DbScaleNode extends Node {
  constructor(options) {
    super(options)

  }

  updateOptions({ width, height, minDb, maxDb, theme } = {}) {
    super.updateOptions(arguments[0] || {})

    if (height || maxDb || minDb) {
      this._interval = this._canvas.height / (this._maxDb - this._minDb)
    }

  }

  async draw(timestamp) {
    // this._ctx.clearRect(0, 0, this._canvas.width, this._canvas.height)
    this._canvas.width |= 0

    const fontSize = 12
    const scaleWidth = 20
    const ctx = this._ctx
    const color1 = this._theme === 'dark' ?
      'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 0.9)'
    const color2 = this._theme === 'dark' ?
      'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.4)'

    ctx.fillStyle = color1
    ctx.font = '300 ' + fontSize + 'px \'Josefin Sans\''
    ctx.textAlign = 'right'
    ctx.lineWidth = .5

    for (let db = this._maxDb; db > this._minDb; db -= 5) {
      const posY = (this._maxDb - db) * this._interval
      const even = (db % 2 === 0) | 0

      if (even) {

        const labelY = posY + fontSize * (posY == 0 ? .8 : .35)
        ctx.fillText(db, scaleWidth * .85, labelY )
        ctx.fillText(db, this._canvas.width - scaleWidth * .1, labelY)

        ctx.strokeStyle = color1
        ctx.setLineDash([1,8])
        ctx.lineDashOffset = 0

      } else {
        ctx.strokeStyle = color2
        ctx.setLineDash([1,16])
        ctx.lineDashOffset = 1

      }

      ctx.beginPath()
      ctx.moveTo( scaleWidth * even, ~~posY + .5 )
      ctx.lineTo( this._canvas.width - scaleWidth * even, ~~posY + .5 )
      ctx.stroke()
    }
    // restore line properties
    ctx.setLineDash([])
    ctx.lineDashOffset = 0
  }

  drawToCtx(containerCanvasContext, x, y) {
    containerCanvasContext.drawImage(this._canvas, x || 0, y || 0)
  }

}
