export default class Node {
  constructor(options) {
    this._canvas = document.createElement('canvas')
    this._ctx = this._canvas.getContext('2d')

    this._options = {}
    this._scalesX = []
    this._scalesY = []

    this.updateOptions(options)

  }

  updateOptions(options) {
    for (const [k, v] of Object.entries(options)) {
      if (v !== undefined) {
        this['_' + k] = v
      }
    }

    if (options.width) {
      this._canvas.width = options.width
    }

    if (options.height) {
      this._canvas.height = options.height
    }

    if (options.theme) {
      this.setTheme(options.theme)
    }

  }

  addScaleX(scaleNode, options) {
    scaleNode.updateOptions({
      width: this._width,
      height: this._height,
      ...options,
    })

    this._scalesX.push(
      scaleNode,
    )
  }

  addScaleY(scaleNode, options) {
    scaleNode.updateOptions({
      width: this._width,
      height: this._height,
      ...options,
    })

    this._scalesY.push(
      scaleNode,
    )
  }

  async draw(timestamp) {
    this.clear()
    await this._draw(timestamp)

    const promises = []

    const allScales = [
      ...this._scalesX,
      ...this._scalesY,
    ]

    allScales.forEach((scale) => {
      promises.push(
        scale.draw(timestamp)
      )
    })

    await Promise.all(promises)

    allScales.forEach((scale) => {
      // this._ctx.drawImage(scale._canvas, 0, 0)
      scale.drawToCtx(this._ctx)
    })
  }

  clear() {
    // this._canvas.width |= 0
    this._ctx.clearRect(0, 0, this._canvas.width, this._canvas.height)
  }

  setTheme(theme) {
    this._theme = theme
    const allScales = [
      ...this._scalesX,
      ...this._scalesY,
    ]
    allScales.forEach(s => s.setTheme(theme))
  }

  async drawResizeContainer() {
    this.clear()
    const canvas = this._canvas
    const ctx = this._ctx
    ctx.lineWidth = 2
    ctx.strokeStyle = 'rgba(0, 0, 0, 50)'
    ctx.strokeRect(
      0,
      0,
      canvas.width,
      canvas.height,
    )

  }

}
