import Node from '@classes/node'


export default class FreqScaleNode extends Node {
  constructor(options) {
    super(options)

    this._freqLabels = {
      log: [20, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 15000, 20000],
      mel: [20, 50, 100, 200, 500, 1000, 2000, 5000, 10000, 15000, 20000],
      linear: [20, 2000, 4000, 6000, 8000, 10000, 12000, 14000, 16000, 18000, 20000],
    }
  }

  updateOptions({ width, height, minFreq, maxFreq, theme, freqScale } = {}) {
    super.updateOptions(arguments[0] || {})
  }

  clear() {
    this._canvas.width |= 0
  }

  async _draw(timestamp) {
    const fontSize = 12

    const color1 = this._theme === 'dark' ?
      'rgba(0, 0, 0, 0)' : 'rgba(0, 0, 0, 0)'
    const color2 = this._theme === 'dark' ?
      'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, 0.9)'

    this._ctx.fillStyle = color1
    this._ctx.fillRect(0, 0, this._canvas.width, this._canvas.height)

    this._ctx.fillStyle = color2
    this._ctx.font = '300 ' + fontSize + 'px \'Josefin Sans\''
    this._ctx.textAlign = 'center'

    this._ctx.strokeStyle = color2
    this._ctx.lineWidth = .9

    let minLog
    let logPxWidth

    if (
      this._freqScale === 'log' ||
      this._freqScale === 'mel'
    ) {
      minLog = Math.log10(this._minFreq)
      logPxWidth = this._canvas.width / (Math.log10(this._maxFreq) - minLog)
    }

    const freqLabels = this._freqLabels[this._freqScale]

    for (const freqIndex in freqLabels) {
      const freq = freqLabels[freqIndex]
      const actualFreq = this._freqScale === 'mel' ?
        (2595 * Math.log10(1 + (freq / 700))) :
        freq
      const label = (actualFreq >= 1000) ? (actualFreq / 1000) + 'k' : actualFreq

      let x
      if (
        this._freqScale === 'log' ||
        this._freqScale === 'mel'
      ) {
        x = logPxWidth * (Math.log10(actualFreq) - minLog)

      } else if (this._freqScale === 'linear') {
        x = (this._canvas.width / (this._maxFreq - this._minFreq)) * (actualFreq - this._minFreq)

      }
      /*const textXPos = freqIndex < (_freqLabels.length - 1) ?
        x + 15 : x - 15*/
      const textXPos = freqIndex > 0 ?
        x - 15 : x + 15

      this._ctx.fillText(label, textXPos, this._canvas.height - (this._canvas.height >> 2))

      if (freqIndex > 0 && freqIndex < freqLabels.length - 1) {
        this._ctx.beginPath()
        this._ctx.moveTo(x + .5, 0)
        this._ctx.lineTo(x + .5, this._canvas.height)
        this._ctx.stroke()
      }
    }

  }

  drawToCtx(containerCanvasContext) {
    containerCanvasContext.drawImage(
      this._canvas, 0, containerCanvasContext.canvas.height - this._canvas.height
    )
  }

}
